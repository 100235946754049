/* eslint-disable react/display-name */
import React, { useState, useContext, useEffect } from 'react';
import useSWR from 'swr';
import { API } from '../../config';
import Context from '../../Context/home';

const endpoint = 'drives';

const withFetch = (Component) => () => {
  const {
    state: { fechaInicio, fechaFin },
    dispatch,
  } = useContext(Context);

  const { data, error } = useSWR(
    endpoint !== '' ? `${API}/${endpoint}/?minDate=${fechaInicio}&maxDate=${fechaFin}` : null,
  );

  useEffect(() => {
    dispatch({ type: 'SET_DATA', payload: data?.result });
  }, [data]);

  return <Component data={data} error={error} />;
};

export default withFetch;
