/* eslint-disable no-console */
import React, { useState, useContext, useEffect } from 'react';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import Button from '@mui/material/Button';
import Context from '../../Context/home';
import toExcelData from '../../utils/toExcelData';

const ExportToExcel = () => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8';
  const fileExtension = '.xlsx';

  const {
    state: { data },
  } = useContext(Context);

  const exportToExcel = async (data) => {
    console.log({ data, excel: toExcelData(data) });
    const ws = XLSX.utils.json_to_sheet(toExcelData(data));
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(blob, 'File' + fileExtension);
  };

  if (data === undefined)
    return (
      <Button variant="contained" disabled>
        Exportar
      </Button>
    );

  if (data.length === 0)
    return (
      <Button variant="contained" disabled>
        Exportar
      </Button>
    );

  return (
    <Button variant="contained" onClick={() => exportToExcel(data)}>
      Exportar
    </Button>
  );
};

export default ExportToExcel;
