/* eslint-disable react/prop-types */
import React, { createContext, useReducer } from 'react';
const Context = createContext();

export const init = {
  fechaInicio: '',
  fechaFin: '',
  data: [],
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_INICIO':
      return { ...state, fechaInicio: action.payload };
    case 'SET_FIN':
      return { ...state, fechaFin: action.payload };
    case 'SET_DATA':
      return { ...state, data: action.payload };
    case 'SET_CLEAN':
      return { ...init };
    default:
      return { ...state };
  }
};

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, init);
  return children ? (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  ) : (
    <h1>Error not children found</h1>
  );
};
export default Context;
