/* eslint-disable no-console */
/* eslint-disable react/display-name */
import React, { useContext } from 'react';
import TextField from '@mui/material/TextField';
import { now } from '../../utils/date';
import Context from '../../Context/home';
export default () => {
  const {
    state: { fechaInicio, fechaFin },
    dispatch,
  } = useContext(Context);

  const handleInicio = (e) => {
    dispatch({ type: 'SET_INICIO', payload: e.target.value });
  };
  const handleFin = (e) => {
    dispatch({ type: 'SET_FIN', payload: e.target.value });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        margin: 30,
      }}
    >
      <TextField
        id="date"
        label="Incio"
        type="date"
        defaultValue={now()}
        sx={{ width: 720 }}
        InputLabelProps={{
          shrink: true,
        }}
        style={{ paddingLeft: 10 }}
        onChange={handleInicio}
      />

      <TextField
        id="date"
        label="Fin"
        type="date"
        defaultValue={now()}
        sx={{ width: 720 }}
        InputLabelProps={{
          shrink: true,
        }}
        style={{ paddingLeft: 10 }}
        onChange={handleFin}
      />
    </div>
  );
};
