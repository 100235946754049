/* eslint-disable no-console */
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { API } from '../../config';
import GenericPdfDownloader from './donwload';
const endpoint = 'drive';

function PdfPage() {
  let { id } = useParams();
  const { data, error, mutate } = useSWR(endpoint !== '' ? `${API}/${endpoint}/${id}` : null);

  if (!id) return <p>No Id found</p>;
  if (!data && !error) return <p>Loading...</p>;
  if (!data && error) return <p>Error</p>;

  return (
    <>
      <div id="divToDownload">{JSON.stringify(data)}</div>

      <GenericPdfDownloader rootElementId="divToDownload" downloadFileName="downloadFile" />
    </>
  );
}

export default PdfPage;
