import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '../../Components/AppBar';
import Table from '../../Components/Table';
import Filter from '../../Components/Filter';
import ExportToExcel from '../../Components/ExportToExcel';
import { Provider } from '../../Context/home';

const HomePage = () => {
  let navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.hasOwnProperty('token')) navigate('/login');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Provider>
      <AppBar />
      <Filter />
      <ExportToExcel />
      <Table />
    </Provider>
  );
};

export default HomePage;
