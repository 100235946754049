/* eslint-disable react/prop-types */
import { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import withData from './withData';

const columns = [
  { id: 'id', label: 'id', minWidth: 70 },
  { id: 'area', label: 'Área', minWidth: 100 },
  { id: 'fecha', label: 'Fecha', minWidth: 100 },
  { id: 'recojo', label: 'Recojo', minWidth: 100 },
  { id: 'destino', label: 'Destino', minWidth: 100 },
  { id: 'solicitante', label: 'Solicitante', minWidth: 100 },
  { id: 'celular', label: 'Celular', minWidth: 100 },
  { id: 'contacto', label: 'Contacto', minWidth: 100 },
  { id: 'celulatContacto', label: 'Celular Contacto', minWidth: 100 },
  { id: 'observacion', label: 'Observación', minWidth: 100 },
  { id: 'observacionConductor', label: 'Observación Conductor', minWidth: 100 },
  { id: 'status', label: 'Estado', minWidth: 100, format: (value) => `${value}`.toUpperCase() },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

const StickyHeadTable = ({ data, error }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (!data && !error)
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <CircularProgress />
      </div>
    );
  if (!data && error) return <p>No se pudo obtener la data</p>;

  if (data && data.length === 0)
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        No hay datos
      </div>
    );

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.result.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.result.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default withData(StickyHeadTable);
