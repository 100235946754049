import { Route, Routes } from 'react-router-dom';
import PdfPage from './pages/Pdf';
import LoginPage from './pages/Login';
import HomePage from './pages/Home';
import { BrowserRouter as Router } from 'react-router-dom';
import { SWRConfig } from 'swr';
import { fetcher } from './fetcher';
import './App.css';

function App() {
  // const push = useNavigate();
  return (
    <SWRConfig
      value={{
        fetcher,
        revalidateOnReconnect: true,
        // refreshInterval: 30000000, //5 min
        // refreshInterval: refresh, //5 min
        // onError: (error, _) => {
        //   if (error.status !== 403 && error.status !== 404) {
        //     // We can send the error to Sentry,
        //     // or show a notification UI.
        //     // console.clear();
        //     toast.error(`${error.message}`, {
        //       position: 'top-right',
        //       autoClose: 5000,
        //       hideProgressBar: false,
        //       closeOnClick: true,
        //       pauseOnHover: true,
        //       draggable: true,
        //       progress: undefined,
        //     });
        //     error.status === 401 && push('/login');
        //   }
        // },
      }}
    >
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/pdf/:id" element={<PdfPage />} />
        </Routes>
      </Router>
    </SWRConfig>
  );
}

export default App;
