const toExcelData = (data) => {
  return data.map((d) => {
    // { id: 'id', label: 'id', minWidth: 70 },
    // { id: 'area', label: 'Area', minWidth: 100 },
    // { id: 'fecha', label: 'Fecha', minWidth: 100 },
    // { id: 'recojo', label: 'Recojo', minWidth: 100 },
    // { id: 'destino', label: 'Destino', minWidth: 100 },
    // { id: 'solicitante', label: 'Solicitante', minWidth: 100 },
    // { id: 'celular', label: 'Celular', minWidth: 100 },
    // { id: 'contacto', label: 'Contacto', minWidth: 100 },
    // { id: 'celulatContacto', label: 'Celular Contacto', minWidth: 100 },
    // { id: 'observacion', label: 'Observación', minWidth: 100 },
    // { id: 'observacionConductor', label: 'Observación Conductor', minWidth: 100 },
    // { id: 'status', label: 'Estado', minWidth: 100, format: (value) => `${value}`.toUpperCase() },
    return {
      Área: d.area,
      Fecha: d.fecha,
      Recojo: d.recojo,
      Destino: d.destino,
      Solicitante: d.solicitante,
      Celular: d.celular,
      Contacto: d.contacto,
      'Celular Contacto': d.celulatContacto,
      Observación: d.observacion,
      'Observación Conductor': d.observacionConductor,
    };
  });
};

export default toExcelData;
